<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <el-button
      style="width: 140px; margin-bottom: 20px"
      type="primary"
      class="btn"
      @click="addReason"
      size="small"
      icon="el-icon-plus"
      >添加取消原因</el-button
    >

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="cancelList"
        v-loading="cancelLoading"
        stripe
        border
        bcollection
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ cancelLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="name" label="取消原因" align="center"> </el-table-column>

        <el-table-column prop="batchName" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleCancel(scope.row.id)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增原因 -->
    <div>
      <el-dialog
        title="取消原因配置"
        :visible.sync="dialogVisible"
        width="450px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          ref="cancelForm"
          :model="cancelForm"
          label-width="80px"
          :rules="cancelRules"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="取消原因" prop="name">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="cancelForm.name"
                  placeholder="请输入取消原因"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="dialogVisible = false" style="margin-right: 20px"
            >取 消
          </el-button>
          <el-button type="primary" @click="handleConfirm" :loading="confirmLoading"
            >保 存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { queryCancels, addCancel, deleteCancel } from "@/api/increment";
export default {
  name: "incrementNewUnconfigure",
  data() {
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("取消原因不能为空"));
      } else {
        callback();
      }
    };
    return {
      cancelList: [],
      cancelLoading: false,
      dialogVisible: false,
      confirmLoading: false,

      //   新增
      cancelForm: {
        name: "",
      },
      cancelRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
      },
    };
  },
  created() {
    this.query();
  },
  methods: {
    query() {
      let data = {};
      queryCancels(data).then((resp) => {
        if (resp.code == 0) {
          this.cancelList = resp.data;
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
      });
    },

    handleCancel(id) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCancel(id).then((resp) => {
            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
            } else {
              this.$notify.error({
                title: "错误",
                message: resp.message,
              });
            }
            this.query();
          });
        })
        .catch(() => {
          return false;
        });
    },
    addReason() {
      this.dialogVisible = true;
    },
    handleConfirm() {
      this.confirmLoading = true;
      let postData = {};
      postData.name = this.cancelForm.name;
      addCancel(postData).then((resp) => {
        if (resp.code == 0) {
          this.$notify.success({
            title: "温馨提示",
            message: resp.message,
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
        this.query();
        this.confirmLoading = false;
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style></style>
